export function useIsOnCmsLandingPage() {
  const route = useRoute()

  const isOnCmsLandingPage = computed<boolean>(() => {
    return route.path?.toString().includes('/lp/')
  })

  return {
    isOnCmsLandingPage,
  }
}
